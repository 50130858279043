









































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WorkspaceOnboarding extends Vue {
  step: number = 1;

  nextStep() {
    this.step++;
    location.href = "#" + this.step;
  }

  goToStep(step: number) {
    this.step = step;
    location.href = "#" + this.step;
  }

  completeOnboarding() {

    this.$router.push({name: 'create-board', params: { workspace_pid: this.$store.state.workspace.pid } })

  }
}
